
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionDivider from "../components/atomic/section-divider"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Arrow from "../resources/images/right-arrow.svg"
import Benefits from "../components/benefits"

import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function MobilneAplikacie() {

  const data = useStaticQuery(graphql`{
  webRef: file(relativePath: {eq: "gyg_show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef2: file(relativePath: {eq: "tjlforms-box.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  webRef3: file(relativePath: {eq: "doku-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  webRef4: file(relativePath: {eq: "tasty-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  crossing: file(relativePath: {eq: "crossing.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
  store: file(relativePath: {eq: "store.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Umelá Inteligencia" />
      
     
      <div className="subhero">   
      <div className="hero">
        <div className="container">
          <div className="row">
          <div className="col-12">
            <div className="title"><h1>Robíme <span style={{color: "#F94166"}}>umelú inteligenciu</span> a strojové učenie.</h1></div>
            <p>Umelá inteligencia môže byť pre Vás brána k lepšiemu rozhodovaniu, riešeniu problémov, efektívnosti, automatizácií, simulácií a množstvu ďalších uplatnení. Dokáže dynamicky zobrazovať reklamu, zvýšiť zisky, zlepšiť chod spoločnosti, zachraňovať životy ale aj generovať dáta potrebné pre simuláciu.</p>
        
          </div>
          </div>
        </div>
      </div> 
      </div>  
    

      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="Our products" white={false}/>
          <div className="row mt40">
            <div className="col-4 section-heading-black" style={{"paddingBottom": "40px"}}>
              <h2>Naše produkty s umelou inteligenciou</h2>
            </div>
            <div className="col-6 section-subtitle-black"><p>Začali sme s jednoduchými implementáciami umelej inteligencie, z ktorých vznikli oceňované systémy.</p></div>
            
          </div>

          <div className="row mt40">
          
            <div className="col-4">
            <GatsbyImage
              image={data.crossing.childImageSharp.gatsbyImageData}
              style={{"height":"100%"}}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Umelá inteligencia" />  
            </div>
            <div className="col-8 process-list">
              
              <h3>Erriate - bezpečné železničné priecestia</h3>
              <p>Erriate monitoruje železničné priecestie s cieľom zistiť prípadné nebezpečné situácie. Umelá inteligencia dokáže detekovať vzorce, ktoré predstavujú rizikové situácie, napríklad uviaznuté auto alebo zranený cyklista.</p>
              <p>Následne dokáže včasne upozorniť vodiča vlaku, zobraziť mu stream z priecestia, na základe ktorého sa môže rozhodnúť či je potrebné vlak zastaviť a odvrátiť nešťastie. </p>
              <p style={{"fontWeight":"800"}}>Cieľom tohto projektu je záchrana ľudských životov a zníženie škôd na majetku. </p>

            </div>
          </div>
          <div className="row mt40">
          
            <div className="col-4">
            <GatsbyImage
              image={data.store.childImageSharp.gatsbyImageData}
              style={{"height":"100%"}}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Umelá inteligencia" />  
            </div>
            <div className="col-8 process-list">
              
              <h3>Univerie</h3>
              <p>Univerie využíva umelú inteligenciu formou video analýzy, ktorá dokáže vyhodnocovať štatistiky z kamenných predajní, zabezpečiť priestor alebo vyhodnotiť neštandardné správanie osôb. Univerie je možné prispôsobiť pre viacero spôsobov aplikácie.</p>
              

            </div>
          </div>
         
        

      
        </div>
      </div>
      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="Useful applications" white={false}/>
          <div className="row mt40">
            <div className="col-4 section-heading-black" style={{"paddingBottom": "40px"}}>
              <h2>AI má množstvo možností využitia, tu je zopár z nich.</h2>
            </div>
            <div className="col-6 section-subtitle-black"> <p>Či už potrebujete automatizovať, simulovať, hľadať problémy alebo lepšie komunikovať so zákazníkmi súčasťou riešenia by mohla byť umelá inteligencia.</p></div>
            
          </div>

          <div className="row mt40">
          
            <div className="col-12">
              <table>
            <thead>
                        <tr>
                        <th>Odvetvie</th>
                        <th>Využitie</th>
                        <th>Popis</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
    <td>Služby zákazníkom</td>
    <td>Sociálne siete</td>
    <td>Sémantická analýza príspevkov Vašich zákazníkov, ich segmentácia alebo automatizácia reklám alebo personalizované odpovede</td>
  </tr>
  <tr>
    <td>Služby zákazníkom</td>
    <td>Chatbot</td>
    <td>Nechajte 24/7 funkčné, inteligentné, samo-sa-zlepšujúce chatovacie roboty, aby zvládli väčšinu otázok a zefektívnili prácu Vašich systémov.</td>
  </tr>
  <tr>
    <td>Služby zákazníkom</td>
    <td>Hlasová autentifikácia</td>
    <td>Autentifikácia zákazníkov bez hesiel využívajúcich biometriu na zlepšenie spokojnosti zákazníkov a opätovné nakupovanie.</td>
  </tr>
  <tr>
    <td>Služby zákazníkom</td>
    <td>Klasifikácia hovorov</td>
    <td>Využite spracovanie jazyka a strojové učenie na určenie cieľov zákazníkov.</td>
  </tr>
  <tr>
    <td>Marketing a predaj</td>
    <td>Segmentácia zákazníkov</td>
    <td>Rozlíšte zákazníkov na skupiny a prispôsobte tak marketing a predaj.</td>
  </tr>
  <tr>
    <td>Marketing a predaj</td>
    <td>Predikcia predaja</td>
    <td>Zistite ako by sa mal vyvíjať predaj produktov a prispôsobte svoj nákup.</td>
  </tr>
  <tr>
    <td>Marketing a predaj</td>
    <td>Predikcia cien</td>
    <td>Predikujte vývoj cien produktov a prispôsobte sa trhu efektívnejšie.</td>
  </tr>
  <tr>
    <td>Marketing a predaj</td>
    <td>Odporúčanie produktov</td>
    <td>Dajte Vašim klientom to čo potrebujú alebo s najvyššou pravdepodobnosťou kúpia.</td>
  </tr>
  <tr>
    <td>Výroba a distribúcia</td>
    <td>Detekcia objektov</td>
    <td>Detekujte pomocou kamier chybné výrobky alebo použite skladové roboty, ktoré sa pohybujú sami.</td>
  </tr>
  <tr>
    <td>Výroba a distribúcia</td>
    <td>Plánovanie distribúcie</td>
    <td>Naplánujte distribúciu rozumne&nbsp;&nbsp;a čo najefektívnejšie.</td>
  </tr>
  <tr>
    <td>Výroba a distribúcia</td>
    <td>Plánovanie nákupu</td>
    <td>Zistite aké množstvo surovín je potrebné nakúpiť v prípade výroby na sklad.</td>
  </tr>
  <tr>
    <td>Výroba a distribúcia</td>
    <td>Meranie efektívnosti výroby</td>
    <td>Zistite či je Vaša výroba efektívna, nájdite slabé a pomalé miesta a optimalizujte tak schopnosť vyrábať.</td>
  </tr>
  <tr>
    <td>Financie</td>
    <td>Detekcia podvodu</td>
    <td>Ak nastane odchylka od štandardu umelá inteligencia to dokáže zachytiť. Taktiež môžeme pôužiť sémantickú analýzu.</td>
  </tr>
  <tr>
    <td>Financie</td>
    <td>Predikcia cashflow</td>
    <td>Pripravte sa na nový rok s umelou inteligenciou. Pomôže Vám pri rozhodnutiach, ktoré sú naviazané na cashflow spoločnosti.</td>
  </tr>
  <tr>
    <td>Financie</td>
    <td>Detekcia chýb v účtovníctve</td>
    <td>Stávajú sa nešťastné chyby, ktoré môžeme minimalizovať pomocou umelej inteligencie.</td>
  </tr>
  <tr>
    <td>Financie</td>
    <td>Plánovanie investícií</td>
    <td>Umelá inteligencia pomôže naplánovať investície do projektov na základe dát z minulosti.</td>
  </tr>
  <tr>
    <td>Firemné procesy</td>
    <td>Automatizácia procesov</td>
    <td>Automatizujte procesy, ktoré uľahčia Vašim zamestnancom životy. Nestrácajte čas na nezmyselných a opakujúcich sa úlohách.</td>
  </tr>
  <tr>
    <td>Firemné procesy</td>
    <td>Digitalizácia dokumentov</td>
    <td>Využite OCR na vytiahnutie údajov z fyzických dokumentov.</td>
  </tr>
  <tr>
    <td>Firemné procesy</td>
    <td>Rozhodovacie stromy</td>
    <td>Neviete sa rozhodnúť alebo je to zložitý proces? Nechajte na to umelú inteligenciu.</td>
  </tr>
  <tr>
    <td>HR</td>
    <td>Plánovanie dovolenkiek</td>
    <td>Plánujte lepšie prácu Vašich zamestnancov, informácie o ich potrebe môžete získať z dát.</td>
  </tr>
  <tr>
    <td>HR</td>
    <td>Hodnotenie kandidátov</td>
    <td>Vytvorte a ohodnoďte formuláre kandidátov uchádzajúcich sa o prácu s jasným výsledokm.</td>
  </tr>
  <tr>
    <td>HR</td>
    <td>Odporúčanie ideálneho uchádzača</td>
    <td>Použite dáta, ktoré máte k dispozícii a pomocou umelej inteligencie zistite presný profil ideálneho uchádzača.</td>
  </tr>
                    </tbody>
                    </table>
            </div>
            
        

        </div>
        </div>
      </div>
      <div className="section-pd  white-bg ">
        <div className="container">
          <SectionDivider name="Clear process" white={false}/>
          <div className="row mt40">
            <div className="col-4 section-heading-black" style={{"paddingBottom": "40px"}}>
              <h2>Pozrite si ako postupujeme.</h2>
            </div>
            <div className="col-6 section-subtitle-black"><p>Máme jasný štandardizovaný proces, ktorý zabezpečí dodržanie termínov a prehľad.</p></div>
            
          </div>

          <div className="row mt40">
          
            <div className="col-6 process-list">
              <div className="number">1.</div>
              <h3>Analýza problému a návrh riešenia</h3>
              <div className="regular-text">Na začiatku stanovujeme celkové ciele projektu, ktoré chceme dosiahnúť implementáciou umelej inteligencie. Tieto ciele sú odrazovým mostíkom pre návrh riešenia, ktoré musí korešpondovať s cieľmi. V návrhu riešenia taktiež stanovíme ako budeme výsledky merať a porovnávať s cieľmi projektu.</div>
            </div>
            <div className="col-6 process-list">
              <div className="number">2.</div>
              <h3>Zhromažďovanie a čistenie dát</h3>
              <div className="regular-text">Ak dáta k navrhovanému riešeniu neexistujú je potrebné ich nazbierať. Bez nich umelá inteligencia nebude fungovať správne a platí pravidlo, čím viac dát tým lepšie. Na dáta použijeme softvérové nástroje, ktoré nám pomôžu odstrániť nežiadúce dáta, doplniť chýbajúce údaje, rozdeliť dáta alebo spojiť ak je to potrebné.</div>
            </div>
          </div>
          <div className="row mt20">
            
            <div className="col-6 process-list">
              <div className="number">3.</div>
              <h3>Tvorba a testovanie modelu umelej inteligencie</h3>
              <div className="regular-text">Následne sa podľa návrhu riešenia vytvorí model umelej inteligencie. Túto fázu môžeme nazývať aj fázou kedy sa umelá inteligencia učí dáta. Na základe modelov, ktoré vytvoríme sa umelá inteligencia rozhoduje. Na konci tejto fázy testujeme či pomocou vytvorených modelov sa umelá inteligencia rozhoduje správne a spĺňa očakávané ciele.</div>
            </div>
            <div className="col-6 process-list">
              <div className="number">4.</div>
              <h3>Vývoj aplikácie s umelou inteligenciou</h3>
              <div className="regular-text">Ak je súčasťou navrhovaného riešenia sprievodný systém alebo sprievodná funkcionalita v tejto fáze sa sústreďujeme na jej vývoj. Postupujeme agilnými metódami tak, aby sme jednotlivé funkcie a súčasti systému mohli čo najrýchlejšie otestovať v praxi.</div>
            </div>
          </div>
          <div className="row mt20">
            <div className="col-3"></div>
            <div className="col-6 process-list">
              <div className="number">5.</div>
              <h3>Testovanie, nasadenie a ďalší vývoj</h3>
              <div className="regular-text">Na koniec testujeme a vyhodnocujeme funkčnosť celého systému a splnenie očakávaných cieľov pomocou meraní, ktoré sme si na začiatku stanovili. Aplikáciu nasadzujeme do produkčnej verzie a analyzujeme jej výkon. Z analytických výsledkov navrhujeme ďalšie návrhy na zlepšenia a vyvíjame systém ďalej.</div>
            </div>
            <div className="col-3"></div>
          </div>
        

      
        </div>
      </div>

     

      <div className="section-pd section-pd black-text white-bg-cut">
      <div className="container">
        <SectionDivider name="references" white={false}/>
        <div className="row">
          <div className="col-4 section-heading">
            <h2>Pozrite sa na čom sme pracovali.</h2>
          </div>
          <div className="col-4 section-subtitle-black mt20">
            <p>Robili sme na zaujímavých projektoch, projekty, ktoré pomáhajú ľuďom. Veď presvedčte sa sami.</p>
          </div>
        </div>

        <div className="row mt40">

        <div className="col-6">
          <AniLink cover direction="left" to="/referencie/gyg" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Softvér na mieru" />  
              <div className="ref-button">
                Softvér na mieru
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/tjl-app" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef2.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Webová aplikácia" />  
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>
        <div className="row mt40">

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/doku" bg="#000" duration={1}><div className="ref-card">
              <GatsbyImage
                image={data.webRef3.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Web aplikácia" />  
              <div className="ref-button">
                Webová aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div></AniLink>
          </div>

          <div className="col-6">
          <AniLink cover direction="left" to="/referencie/tasty" bg="#000" duration={1}>
            <div className="ref-card">
              <GatsbyImage
                image={data.webRef4.childImageSharp.gatsbyImageData}
                style={{"height":"100%"}}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Mobilná aplikácia" />  
              <div className="ref-button">
                Mobilná aplikácia
                  <div className="arrow">
                    <img src={Arrow} width="30px" alt=""></img>
                  </div>
              </div>
            </div>
            </AniLink>
          </div>
          
        </div>
          
           
      </div>
          
    </div>
    <Benefits />
    </Layout>
  );

}
