import React from "react"
import SectionDivider from "./atomic/section-divider"
import Teaser from "../components/atomic/teaser"


export default function HeroMain() {



  return (
    <div className="section-pd white-text">
      <div className="container">
        <SectionDivider name="All in one" white={true}/>
        <div className="row">
          <div className="col-4">
            <div className="row section-heading-white"><h2>Bezpečnosť, rýchlosť,  škálovateľnosť, analytika.</h2></div>
            <div className="row section-subtitle-white"><p>Pracujeme s modernými technológiami, pomocou ktorých zaručujeme <span style={{"fontWeight":"600"}}>bezpečnosť, rýchlosť a škálovateľnosť.</span></p> </div>
          </div>
          
          
          <div className="col-4 ">
            <div className="mb40">
              <Teaser name="Bezpečnosť" link="/ako-to-robime">Ochrana dát, zdrojového kódu a Vašich zákazníkov ide v popredí všetkého. Nemôžeme dopustiť bezpečnostné riziká a naše aplikácie podrobujeme bezpečnostným a penetračným testom.</Teaser>
            </div>
            <div className="mb40">
              <Teaser name="Rýchlosť" link="/ako-to-robime"> Vyvíjame aplikácie pomocou najmodernejších technológií, ktoré nám pomáhajú dosahovať rýchlosti aplikácií s načítaním do 1 sekundy.</Teaser>
            </div>
          </div> 
          
          <div className="col-4 ">
            <div className="mb40">
              <Teaser name="Škálovateľnosť" link="/ako-to-robime">Používame Kubernetes a Docker pre kontajnerizáciu aplikácií, ich pohodlnejšiu správu a nekonečnú škálovateľnosť.</Teaser>
            </div>
            <div className="mb40">
              <Teaser name="Analytika" link="/ako-to-robime">Naše aplikácie podrobujeme testovaním v reálnom živote tak, aby sme získali drahocenné údaje pre lepšiu použiteľnosť pre používateľov, efektívnosť a pre odhaľovanie slabých miest návrhu.</Teaser>
            </div>
          </div>
        </div>    
      </div>
          
    </div>


    )
  
}


