import React from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"


export default function Teaser(props) {

    return (
        <div className="row">
          <div className="col">
            <div className="teaser-title">{props.name}</div> 
            <div className="teaser-p">{props.children}</div>
            <div className="teaser-link"><AniLink cover direction="left" bg="#000" duration={1} to={props.link}>Čítať viac</AniLink></div>
          </div>
        </div>
    )
    
}
Teaser.propTypes = {
  children: PropTypes.node.isRequired,
}